<template>
  <div class="page">
    <!-- 筛选 -->
    <div class="top_box">
      <div class="box">
        <div class="l_box" @click="orderTypeShow = true">
          <div class="in_box">
            <div class="select_box">
              {{ orderTypeStr }}
              <img src="./img/index/triangle.png" alt="" />
            </div>
          </div>
        </div>
        <div class="r_box" @click="stateTypeShow = true">
          <div class="in_box">
            <div class="select_box">
              {{ stateTypeStr }}
              <img src="./img/index/triangle.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toDetail(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item_t">
            <div class="status_box">
              <p v-if="item.status == 1"><span class="red"></span>待受理</p>
              <p v-else-if="item.status == 2">
                <span class="blue"></span>已接单
              </p>
              <p v-else-if="item.status == 3">
                <span class="yellow"></span>处理中
              </p>
              <p v-else><span class="green"></span>已完成</p>
            </div>
            <div class="time">{{ item.createTime }}</div>
          </div>
          <div class="item_c">
            <p>
              工单编号：<span>{{ item.id }}</span>
            </p>
            <p>
              工单类型：<span>{{ item.workTypeName }}</span>
            </p>
            <p>
              发布人：<span>{{ item.name }}</span>
            </p>
          </div>
          <div class="item_b" v-if="item.status == 1">
            <div class="phone_box" @click.stop="callSomeOne(item.phone)">
              <img src="./img/index/phone.png" alt="" />
              电话联系
            </div>
            <div
              class="reassignment_btn"
              @click.stop="toPersonnelTransfer(item)"
            >
              转派
            </div>
            <div
              class="yellow_btn"
              @click.stop="manageWorkOrder(2, '接单', item.id)"
            >
              接单
            </div>
          </div>
          <div class="item_b" v-if="item.status == 2">
            <div class="phone_box" @click.stop="callSomeOne(item.phone)">
              <img src="./img/index/phone.png" alt="" />
              电话联系
            </div>
            <div
              class="reassignment_btn"
              @click.stop="toPersonnelTransfer(item)"
            >
              转派
            </div>
            <div
              class="yellow_btn"
              @click.stop="manageWorkOrder(3, '处理', item.id)"
            >
              处理
            </div>
          </div>
          <div class="item_b" v-if="item.status == 3">
            <div class="phone_box" @click.stop="callSomeOne(item.phone)">
              <img src="./img/index/phone.png" alt="" />
              电话联系
            </div>
            <div
              class="complete_btn"
              @click.stop="manageWorkOrder(4, '完成', item.id)"
            >
              完成
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <!-- 工单类型 -->
    <v-picker
      :columns="workOrderTypeList"
      :valueShow="orderTypeShow"
      :valueKey="orderTypeKey"
      @clickOverlay="orderTypeClickOverlay"
      @cancel="orderTypeCancelPick"
      @confirmPicker="orderTypeConfirm"
    ></v-picker>
    <!-- 状态类型 -->
    <v-picker
      :columns="stateTypeColumns"
      :valueShow="stateTypeShow"
      :valueKey="stateTypeKey"
      @clickOverlay="stateTypeClickOverlay"
      @cancel="stateTypeCancelPick"
      @confirmPicker="stateTypeConfirm"
    ></v-picker>
    <v-dialog
      confirmButtonColor="#3680FF"
      v-model="isDialog"
      title="提示"
      @confirm="manageWorkOrder"
    >
      <p class="transfer_message">是否{{ title }}？</p>
    </v-dialog>
  </div>
</template>

<script>
import {
  workOrderListUrl,
  workOrderTypeListUrl,
  manageWorkOrderUrl,
} from "./api.js";
import { orderType, stateType } from "./map.js";
export default {
  name: "myWorkOrder",
  data() {
    return {
      title: "",
      isDialog: false,
      manageWorkOrderStatus: null,
      id: null,

      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },

      status: null,
      workType: null,

      // 工单类型
      orderTypeColumns: orderType,
      orderTypeShow: false,
      orderTypeKey: "text",
      orderTypeStr: "全部",
      // 状态类型
      stateTypeColumns: stateType,
      stateTypeShow: false,
      stateTypeKey: "text",
      stateTypeStr: "全部",
      list: [],

      workOrderTypeList: [
        {
          text: "全部",
          id: null,
        },
      ],
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.getWorkOrderTypeList();
  },
  methods: {
    manageWorkOrder(status, name, id) {
      //处理/完成/接单
      if (this.isDialog == false) {
        this.title = name;
        this.manageWorkOrderStatus = status;
        this.id = id;
        this.isDialog = true;
      } else {
        let params = {
          id: this.id,
          type: this.manageWorkOrderStatus,
          response: this.message,
        };
        this.$axios
          .post(`${manageWorkOrderUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.isDialog = false;
              this.init();
            }
          });
      }
    },
    getWorkOrderTypeList() {
      //获取工单类型列表
      let params = {
        tenantId: this.tenantId,
      };
      this.$axios
        .get(`${workOrderTypeListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((item) => {
              item.text = item.name;
              this.workOrderTypeList.push(item);
            });
            console.log(this.workOrderTypeList);
          }
        });
    },
    onLoad() {
      this.getMyWorkOrderList();
    },
    getMyWorkOrderList() {
      //获取工单列表
      let params = {
        userId: this.userId,
        workTypeName: this.workType,
        status: this.status,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios
        .get(`${workOrderListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
    },
    callSomeOne(phoneNum) {
      //电话联系
      window.location.href = `tel://${phoneNum}`;
    },
    toPersonnelTransfer(item) {
      //转派按钮
      console.log("转派");
      this.$router.push({
        name: "personnelTransfer",
        query: {
          id: item.id,
        },
      });
    },
    toDetail(item) {
      //跳转详情
      this.$router.push({
        name: "serviceWorkOrderDetail",
        query: {
          id: item.id,
        },
      });
    },
    // 工单类型
    orderTypeClickOverlay() {
      this.orderTypeShow = false;
    },
    orderTypeCancelPick() {
      this.orderTypeShow = false;
    },
    orderTypeConfirm(value) {
      console.log(value);
      this.orderTypeStr = value.text;
      this.workType = value.name;
      this.orderTypeShow = false;
      this.init();
    },
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.$refs.list._data.loading = true;
      this.finished = false;
      this.getMyWorkOrderList();
    },
    // 状态类型
    stateTypeClickOverlay() {
      this.stateTypeShow = false;
    },
    stateTypeCancelPick() {
      this.stateTypeShow = false;
    },
    stateTypeConfirm(value) {
      console.log(value);
      this.stateTypeStr = value.text;
      this.status = value.id;
      this.stateTypeShow = false;
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  padding-bottom: 30px;
  box-sizing: border-box;

  .transfer_message {
    text-align: center;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    padding: 20px 0;
  }

  .list {
    width: 100%;
    padding: 0 35px;
    box-sizing: border-box;

    .item {
      margin-top: 24px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(228, 225, 225, 0.5);
      border-radius: 16px;
      padding: 0 22px;
      box-sizing: border-box;

      .item_b {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 92px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .reassignment_btn {
          padding: 0 76px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 28px;
          color: #5594ff;
          box-sizing: border-box;
          border-radius: 10px;
          border: 2px solid;
        }

        .complete_btn {
          padding: 0 76px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 28px;
          color: #fff;
          box-sizing: border-box;
          border-radius: 10px;
          background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
        }

        .yellow_btn {
          padding: 0 76px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 28px;
          color: #fff;
          box-sizing: border-box;
          border-radius: 10px;
          background: linear-gradient(180deg, #fdc870 0%, #faac3b 100%);
        }

        .phone_box {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          align-items: center;

          img {
            width: 26px;
            height: 28px;
            margin-left: 14px;
            margin-right: 6px;
          }
        }
      }

      .item_c {
        box-sizing: border-box;
        padding: 16px 0;
        border-top: 2px solid rgba(0, 0, 0, 0.1);

        p {
          margin-left: 10px;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 8px;

          span {
            color: #333333;
          }
        }

        p:nth-last-child(1) {
          margin-bottom: 0;
        }
      }

      .item_t {
        width: 100%;
        height: 74px;
        display: flex;

        .time {
          flex: 1;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.41);
          text-align: right;
          line-height: 74px;
        }

        .status_box {
          flex: 1;
          font-size: 26px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          align-items: center;
          margin-left: 8px;

          p {
            display: flex;
            align-items: center;

            span {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 10px;
              display: inline-block;
            }

            .red {
              background: #c33333;
            }

            .blue {
              background: #8db0e8;
            }

            .yellow {
              background: #f9980b;
            }

            .green {
              background: #66b95a;
            }
          }
        }
      }
    }
  }

  .top_box {
    width: 100%;
    height: 92px;
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);

    .box {
      width: 100%;
      height: 92px;
      display: flex;
      // text-align: center;
      // align-items: center;
      position: fixed;
      background: #ffffff;
      top: 0;
      left: 0;
    }

    .l_box,
    .r_box {
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      // padding: 0 140px;

      .in_box {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 92px;
        white-space: nowrap;
        position: relative;

        .select_box {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          text-align: center;

          img {
            width: 18px;
            height: 12px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
